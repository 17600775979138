import { defineStore } from "pinia";
import { listTransportServices } from "@/services/api/auxiliary";
import { TransportService } from "@/interfaces/auxiliary.types";

interface TransportState {
  transportServices: TransportService[];
  loading: boolean;
}

export const useTransportStore = defineStore("Transport", {
  state: (): TransportState => ({
    transportServices: [],
    loading: false,
  }),
  actions: {
    async fetchTransportServices() {
      this.setLoading(true);
      try {
        this.transportServices = await listTransportServices();
      } catch (error) {
        console.error("Failed to fetch transpor services:", error);
      } finally {
        this.setLoading(false);
      }
    },

    clear() {
      this.transportServices = [];
    },

    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
  },
});
