import { defineStore } from "pinia";
import { fetchUser, login } from "@/services/api/user";
import { User } from "@/interfaces/auth.types";
import { useOrderStore } from "@/store/orders";
import { useAccountStore } from "@/store/accounts";
import { useCourseStore } from "@/store/courses";
import { useInvoiceStore } from "@/store/invoices";
import { useTransportStore } from "@/store/transport";
interface AuthState {
  accessToken: string | null;
  user: User | null;
  organization: any;
  memberships: any[];
}
export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    accessToken: null,
    user: null,
    organization: null,
    memberships: [],
  }),
  getters: {
    isAuthenticated(state): boolean {
      return state.accessToken !== null;
    },
    me(state): User | null {
      return state.user;
    },
    userPermissions(state): Set<string> {
      const permissions = new Set<string>();
      state.user?.roles.forEach((role) => {
        role.permissions?.forEach((permission) => {
          permissions.add(permission.name);
        });
      });
      return permissions;
    },
  },
  actions: {
    async updateUser() {
      this.user = await fetchUser();
    },
    async login(email: string, password: string) {
      try {
        const response = await login(email, password);
        if (response) {
          this.user = response.user;
          this.organization = response.organization;
          this.memberships = response.memberships;
          this.setAccessToken(response.token);
          return true;
        }
      } catch (error) {
        console.log(error);
        return { error: "Forkert kombination af e-mail og kodeord." };
      }
    },
    logout() {
      this.clear();
      useOrderStore().clear();
      useAccountStore().clear();
      useCourseStore().clear();
      useInvoiceStore().clear();
      useTransportStore().clear();
    },
    clear() {
      this.setAccessToken("");
      this.accessToken = null;
      this.user = null;
    },
    setAccessToken(token: string) {
      this.accessToken = token;
      localStorage.setItem("accessToken", token);
    },
    initializeStore() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        this.accessToken = token;
      }
    },
  },
  persist: {
    enabled: true,
  },
});
