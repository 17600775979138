import { apiClient } from "@/services/api";
import { User } from "@/interfaces/auth.types";
import { LoginResponse } from "@/interfaces/responses.types";

/**
 * Login function
 *
 * @param {string} email
 * @param {string} password
 * @returns {Promise<LoginResponse>} - A promise that resolves to the LoginResponse
 */
const login = (email: string, password: string): Promise<LoginResponse> => {
  return apiClient
    .post<LoginResponse>("/api/login", {
      email,
      password,
    })
    .then((response) => response.data);
};

const forgotPassword = (email: string): Promise<void> => {
  return apiClient.post("/api/forgot-password", { email });
};

const resetPassword = (
  token: string,
  email: string,
  password: string,
  password_confirmation: string,
): Promise<any> => {
  return apiClient.post("/api/reset-password", {
    token,
    email,
    password,
    password_confirmation,
  });
};
/**
 * Fetches current user data
 *
 * @return {Promise<User>} A promise that resolves with the fetched user data.
 */
const fetchUser = (): Promise<User> => {
  return apiClient.get<User>("/user").then((response) => response.data);
};

const deleteUser = (): Promise<void> => {
  return apiClient.delete("/user");
};

export { login, fetchUser, forgotPassword, resetPassword };
