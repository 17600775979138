<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useAuthStore } from "@/store/auth";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

export default defineComponent({
  components: {
    DefaultLayout,
    AuthenticatedLayout,
  },
  setup() {
    const authStore = useAuthStore();
    const layout = computed(() => {
      return authStore.isAuthenticated
        ? "AuthenticatedLayout"
        : "DefaultLayout";
    });

    return { layout };
  },
});
</script>
