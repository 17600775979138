import { apiClient } from "@/services/api";
import { TransportService } from "@/interfaces/auxiliary.types";
import { Country } from "@/interfaces/auxiliary.types";

/**
 * List all accounts.
 *
 * @returns {Promise<Account[]>} A promise that resolves to an array of accounts.
 */
const listTransportServices = (): Promise<TransportService[]> => {
  return apiClient
    .get<TransportService[]>("/aux/transport/services")
    .then((response) => response.data);
};

const listCountries = (): Promise<Country[]> => {
  return apiClient
    .get<Country[]>("/aux/countries")
    .then((response) => response.data);
};

export { listTransportServices, listCountries };
